<template>
  <div id="app">
    <div id="wrapper">
      <canvas id="the-canvas"></canvas>
      <!-- <SpotifyPlayer/> -->

      <ul id="social">
        <li>
          <span class="icon">
            <img src="/images/facebook.svg">
          </span>
          <span>AHMAD.GH00ST</span>
        </li>
        <li>
          <span class="icon">
            <img src="/images/youtube.svg">
          </span>
          <span>AHMAD GHOST</span>
        </li>
        <li>
          <span class="icon">
            <img src="/images/twitch.svg">
          </span>
          <span>GHOSTAHMAD022</span>
        </li>
      </ul>

      <!-- <div id="playback">
        <button class="playback-btn"></button>
      </div> -->

    </div>
  </div>
</template>

<script>

// import SpotifyPlayer from './components/SpotifyPlayer';

export default {
  name: 'App',
  components: {
    // SpotifyPlayer
  },

  data() {
    return {
      Wrapper: null,
      Canvas: null,
      ctx: null,
      BG: null,
      currentBg: null,
      colors: [
          "#b4b2b5",
          "#dfd73f",
          "#6ed2dc",
          "#66cf5d",
          "#c542cb",
          "#d0535e",
          "#3733c9"
      ]
    }
  },
  methods: {
    texts: function (color) {
        this.ctx.font = "20vh Bungee Outline";
        this.ctx.shadowBlur = 30;
        this.ctx.shadowColor = color;
        this.ctx.fillStyle = color;
        this.ctx.setTransform(1, -0.15, 0, 1, 0, -10);
        this.ctx.fillText("AHMAD", 400, this.Wrapper.height / 2 - 5);

        this.ctx.fillStyle = "white";
        this.ctx.shadowBlur = 30;
        this.ctx.shadowColor = color;
        this.ctx.fillText("AHMAD", 400, this.Wrapper.height / 2);

        this.ctx.font = "18vh Bungee Inline";
        this.ctx.shadowBlur = 30;
        this.ctx.shadowColor = color;
        this.ctx.fillStyle = "#fff";
        this.ctx.setTransform(1, -0.15, 0, 1, 0, -10);
        this.ctx.fillText(
            "GHOST",
            400,
            this.Wrapper.height / 2 + this.Wrapper.height / 10
        );

        this.ctx.textAlign = "center";
        this.ctx.textBaseline = "middle";
    },
    glitch: function () {

        let rAF = window.requestAnimationFrame(this.glitch);

        this.ctx.fillStyle = "#1a191c";
        this.ctx.fillRect(0, 0, this.Wrapper.width, this.Wrapper.height);

        this.ctx.drawImage(this.BG, 0, 0, this.Wrapper.width, this.Wrapper.height);

        this.texts(this.colors[Math.floor(Math.random() * 7)]);
        this.ctx.shadowBlur = 0;
        this.ctx.shadowColor = "none";
        this.ctx.setTransform(1, 0, 0, 1, 0, 0);

        for (let i = 0; i < 1000; i++) {
            this.ctx.fillStyle = `rgba(255, 255, 255, ${Math.random() * 0.01})`;
            this.ctx.fillRect(
                Math.floor(Math.random() * this.Wrapper.width),
                Math.floor(Math.random() * this.Wrapper.height),
                Math.floor(Math.random() * 30) + 1,
                Math.floor(Math.random() * 30) + 1
            );

            this.ctx.fillStyle = `rgba(0,0,0,${Math.random() * 0.1})`;
            this.ctx.fillRect(
                Math.floor(Math.random() * this.Wrapper.width),
                Math.floor(Math.random() * this.Wrapper.height),
                Math.floor(Math.random() * 25) + 1,
                Math.floor(Math.random() * 25) + 1
            );

        }

        this.ctx.fillStyle = this.colors[Math.floor(Math.random() * 40)];
        this.ctx.fillRect(
            Math.random() * this.Wrapper.width,
            Math.random() * this.Wrapper.height,
            Math.random() * this.Wrapper.width,
            Math.random() * this.Wrapper.height
        );
        this.ctx.setTransform(1, 0, 0, .8, .2, 0);
    }
  },
  mounted() {
    this.Canvas = document.getElementById('the-canvas');
    this.ctx = this.Canvas.getContext("2d");
    let linePos = 0;

    this.Wrapper = {
      el: document.getElementById('wrapper'),
      width: document.getElementById('wrapper').offsetWidth,
      height: document.getElementById('wrapper').offsetHeight,
    };
    this.Canvas.width = this.Wrapper.width;
    this.Canvas.height = this.Wrapper.height;
    
    this.BG = new Image;
    this.BG.src = `/images/bg0.jpg`;
    this.currentBg = 0;

    setInterval(() => {
      this.currentBg++;
      this.BG.src = `/images/bg${this.currentBg}.jpg`;
      console.log();
      
      if (this.currentBg >= 2) {
          this.currentBg = 0;
      }
    }, 4000);

    this.glitch();

    window.addEventListener('resize', () => {
        this.Canvas.width = this.Wrapper.width;
      this.Canvas.height = this.Wrapper.height;
    });

  }
}
</script>

<style lang="scss">

@import url('https://fonts.googleapis.com/css2?family=Syne+Mono&display=swap');
@import url('https://fonts.googleapis.com/css?family=Fira+Mono:400');
@import url('https://fonts.googleapis.com/css2?family=Bungee+Inline&family=Bungee+Outline&display=swap');

html, body, #app {
  margin: 0;
  padding: 0;
}

#wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
}

#playback {
  position: fixed;
  right: 25px;
  bottom: 25px;
  z-index: 100;
}

#social {
  position: fixed;
  left: 25px;
  bottom: 25px;
  z-index: 100;
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;

  li {
    line-height: 60px;
    font-size: 24px;
    color: #fff;
    display: flex;
    align-items: center;
    margin: 8px 0;
    font-family: 'Bungee Inline', serif;

    &:not(:last-child) {
      margin-right: 1rem;
    }

    .icon {
      display: inline-block;
      width: 60px;
      height: 60px;
      margin-right: .5rem;
      border-radius: 100%;
      background: #fff;
      padding: 10px;
      box-sizing: border-box;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.playback-btn {
  width: 75px;
  height: 75px;
  background: #fff;
  border-radius: 100%;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}
</style>
